<script>
import {
    layoutComputed
} from '@state/helpers'
// import Vertical from '@router/layouts/vertical'
// import Horizontal from '@router/layouts/horizontal'

export default {
    components: {
        // Vertical,
        // Horizontal
    },
    data() {
        return {}
    },
    computed: {
        ...layoutComputed,
    },
    methods: {},
}
</script>

<template>
<div>
    <transition mode="out-in">
        <router-view />
    </transition>

</div>
</template>
